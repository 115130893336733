import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Payments')))]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('order-payment-details',{staticClass:"d-flex flex-column",attrs:{"value":_vm.order}})],1),_c('div',{staticClass:"d-flex flex-gap-16 mt-4"},[(_vm.showRefund)?_c('order-action-refund',{model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}):_vm._e(),(_vm.showMarkAsPaid)?_c('order-action-mark-as-paid',{model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}):_vm._e(),(_vm.showVoid)?_c('order-action-void',{model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}):_vm._e(),(_vm.showCapture && _vm.authorizationTransaction)?_c('order-action-capture-payment',{attrs:{"transaction-id":_vm.authorizationTransaction.id},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}):_vm._e()],1),(_vm.value.payment.type === 'online' && _vm.canVoid(_vm.order.status))?_c('div',{staticClass:"text-caption grey--text mt-4"},[_c('span',[_vm._v(_vm._s(_vm.$t('order.transaction.void')))])]):_vm._e(),_c(VDivider,{staticClass:"my-4"}),_c('h2',[_vm._v(_vm._s(_vm.$t('Payment Transactions')))]),_c(VDataTable,{attrs:{"items":_vm.order.transactions,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.provider))]),_c('small',[_vm._v(_vm._s(item.id))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.amount}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getTransactionStatusColor(item.status),"label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getTransactionStatus(item.status)))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }